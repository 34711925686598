import firebase from 'gatsby-plugin-firebase';
import React, { createContext, memo, useContext, useRef } from 'react';
import { toast } from 'react-toastify';
import ShortUniqueId from 'short-unique-id';
import { isFileImage, isFilePDF } from '../utils';
import { useDispatch, useSelector } from './ResumeContext';
import UserContext from './UserContext';

const defaultState = {
  uploadPhotograph: async () => {},
  uploadCV: async () => {},
  deleteCV: async () => {},
};

const StorageContext = createContext(defaultState);

const StorageProvider = ({ children }) => {
  const dictionary = 'abcdefghijklmnopqrstuvwxyz1234567890'.split('');
  const uuid = new ShortUniqueId({ dictionary });

  const toastId = useRef(null);

  const { user } = useContext(UserContext);

  const id = useSelector('id');
  const dispatch = useDispatch();

  const uploadPhotograph = async (file) => {
    if (!file) {
      return null;
    }

    if (!isFileImage(file)) {
      toast.error(
        "You tried to upload a file that was not an image. That won't look good on your resume. Please try again.",
      );
      return null;
    }

    if (file.size > 2097152) {
      toast.error(
        "Your image seems to be bigger than 2 MB. That's way too much. Maybe consider reducing it's size?",
      );
      return null;
    }

    const uploadTask = firebase
      .storage()
      .ref(`/users/${user.uid}/photographs/${id}`)
      .put(file);

    let progress = 0;
    toastId.current = toast('Firing up engines...', {
      progress,
    });

    uploadTask.on(
      'state_changed',
      (snapshot) => {
        progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        toast.update(toastId.current, {
          render: 'Uploading...',
          progress,
          hideProgressBar: false,
        });
      },
      (error) => toast.error(error),
      async () => {
        const downloadURL = await uploadTask.snapshot.ref.getDownloadURL();
        dispatch({
          type: 'on_input',
          payload: {
            path: 'profile.photograph',
            value: downloadURL,
          },
        });

        toast.update(toastId.current, {
          render:
            'Your photograph was uploaded successfully... and you look great!',
          progress,
          autoClose: 2000,
          hideProgressBar: true,
        });
      },
    );
  };

  const uploadCV = async (file) => {
    const newId = uuid();
    if (!file) {
      return null;
    }

    if (!isFilePDF(file)) {
      toast.error(
        'You tried to upload a file that was not an pdf. Please try again.',
      );
      return null;
    }

    if (file.size > 10485760) {
      toast.error(
        "Your pdf seems to be bigger than 10 MB. That's way too much. Maybe consider reducing it's size?",
      );
      return null;
    }

    const uploadTask = firebase
      .storage()
      .ref(`/users/${user.uid}/cv/${newId}-${file.name}`)
      .put(file);

    let progress = 0;
    toastId.current = toast('Firing up engines...', {
      progress,
    });

    uploadTask.on(
      'state_changed',
      (snapshot) => {
        progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        toast.update(toastId.current, {
          render: 'Uploading...',
          progress,
          hideProgressBar: false,
        });
      },
      (error) => toast.error(error),
      async () => {
        const downloadURL = await uploadTask.snapshot.ref.getDownloadURL();
        const cvParams = {
          id: newId,
          idFile: `${newId}-${file.name}`,
          cv: downloadURL,
          createdAt: firebase.database.ServerValue.TIMESTAMP,
          updatedAt: firebase.database.ServerValue.TIMESTAMP,
          user: user.uid,
          name: file.name,
          public: true,
          isUpload: true,
        };
        firebase.database().ref(`resumes/${newId}`).set(cvParams);

        toast.update(toastId.current, {
          render: 'Your cv was uploaded successfully...',
          progress,
          autoClose: 2000,
          hideProgressBar: true,
        });
      },
    );
  };

  const deleteCV = async (resume) => {
    const storageRef = firebase.storage().ref();
    const desertRef = storageRef.child(
      `/users/${resume.user}/cv/${resume.idFile}`,
    );

    // Delete the file
    desertRef
      .delete()
      .then(() => {
        // File deleted successfully
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  return (
    <StorageContext.Provider
      value={{
        uploadPhotograph,
        uploadCV,
        deleteCV,
      }}
    >
      {children}
    </StorageContext.Provider>
  );
};

export default StorageContext;

const memoizedProvider = memo(StorageProvider);

export { memoizedProvider as StorageProvider };
